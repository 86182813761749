/* .prediction-container {} */

.prediction-container h3 {
    position: absolute;
    bottom: -1%;
    left: 3%;
    z-index: 10;
    font-size: 0.9rem;
}

.prediction-graph {
    width: 100%;
    height: calc(85vh/2);
}

.legend-container {
    position: absolute;
    top: 16%;
    left: 3%;
    z-index: 10;
    background: rgba(41, 41, 41, 0.4);
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 8px;
    /* font-family: 'Roboto', sans-serif; */
}

/* Mobile Style */  
.mobile-container .legend-container {
    top: 18%;
}

.mobile-container .prediction-graph {
    margin-top: 2vh;
    height: calc(68vh);
    
}