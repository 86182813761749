.changa-font {
    font-family: "Changa", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

body {
    background-color: var(--primary-color);
    color: var(--text-light-color);
}

body::-webkit-scrollbar {
    display: none;
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

button:focus,
input:focus {
    outline: none;
}

.container {
    max-width: calc((2 / 3) * 100%);
}

.custom-search label {
    font-size: 1.1rem;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.form-group {
    flex: 1;
    margin-right: 10px;
}

.form-group:last-child {
    margin-right: 0;
}

.token-dash-post,
.token-post {
    border: 5px solid var(--border-prime);
    border-radius: 33px;
    padding: 5px 15px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-template-rows: auto auto auto;
    grid-gap: 0;
    align-items: center;
    background-color: var(--secondary-color);
    color: var(--text-light-color);
    box-shadow: 0 2px 4px rgba(217, 217, 217, 0.1);
    transition: transform .15s ease-in-out, box-shadow .15s ease-in-out;
}

.img-button {
    grid-row: span 2;
    width: 100%;
    max-width: 80px;
    border-radius: 50%;
    border: 5px solid var(--border-prime);
}

.img-button:hover {
    border: 5px solid #8C4579;
    cursor: pointer;
}

.token-dash-post:hover,
.token-post:hover {
    transform: translate(10px, -5px);
    box-shadow: -10px 10px 5px var(--border-second);
}

.token-name-symbol {
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--text-light-color);
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.token-quote {
    font-size: 16px;
    color: var(--text-light-color);
}

.token-date {
    font-size: 14px;
    color: var(--text-light-color);
    margin: auto 0;
}

.swap-mechanic {
    text-align: left;
    width: 100%;
    color: var(--text-light-color);
}

.token-info {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto auto;
}

.token-info-container {
    display: flex;
}

.token-info-col-left {
    width: 45%;
    padding: 10px;
    padding-right: 0;
}

.token-info-col-right {
    display: flex;
    width: 55%;
    padding: 0;
    padding-left: 10px;
}

.token-price,
.token-network,
.token-liquidity {
    font-size: 0.9rem;
    font-weight: bold;
    color: var(--text-light-color);
}

.token-socials {
    grid-column: span 2;
    /* Span across both columns */
    display: flex;
    flex-wrap: wrap;
}

.token-socials span {
    margin-right: 10px;
}

.token-info>div {
    background-color: var(--secondary-color);
    padding: 10px;
    border-radius: 5px;
}

.token-socials a {
    margin-right: 10px;
    text-decoration: none;
    color: var(--highlight-prime);
    font-size: 16px;
}

.token-socials a:hover {
    color: var(--default-second);
}

.token-address {
    cursor: pointer;
    color: var(--highlight-prime);
    display: inline-flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    transition: color .15s ease-in-out;
}

.token-address:hover {
    color: var(--highlight-second);
}

.token-symbol {
    cursor: pointer;
    color: var(--highlight-prime);
    transition: color .15s ease-in-out;
}

.token-symbol:hover {
    color: var(--highlight-second);
}

.horizontal-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    margin-bottom: 15px;
}

.main-button, .secondary-button {
    font-weight: bold;
    font-size: 1rem;
    color: var(--text-light-color);
    padding: 8px 12px;
    min-width: 75px;
    border-radius: 10px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.main-button {
    background-color: var(--default-prime);
    border: 1px solid var(--default-prime);
}

.main-button:hover {
    background-color: var(--default-second);
    color: #26231E;
}

.secondary-button {
    background-color: var(--alert-prime);
    border: 1px solid var(--alert-prime);
}

.secondary-button:hover {
    background-color: var(--alert-second);
    color: var(--text-dark-color);
}

.neutral-button {
    background-color: var(--warning-prime);
    border: 1px solid var(--border-prime);
    font-weight: bold;
    font-size: 1rem;
    color: var(--text-light-color);
    padding: 10px 15px;
    min-width: 75px;
    border-radius: 15px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.neutral-button:hover {
    background-color: var(--warning-second);
    color: var(--text-dark-color);
}

.form-row-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.input-form {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--text-dark-color);
    background-color: var(--text-light-color);
    background-clip: padding-box;
    border: 1px solid var(--text-light-color);
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.select-form {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--text-dark-color);
    background-color: var(--text-light-color);
    background-clip: padding-box;
    border: 1px solid var(--text-light-color);
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.select2-container {
    box-sizing: border-box;
    display: block;
    margin: 0;
    position: relative;
    padding: 5px 0;
    height: 100%;
    width: 100% !important;
}

.select2-container--default .select2-selection--single {
    appearance: none;
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: var(--text-dark-color);
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 5px;
}

.custom-modal-style {
    background-color: var(--text-light-color);
    color: var(--golden-prime);
    border-radius: 15px;
}

.modal-header {
    border-bottom: 1px solid var(--golden-second);
}

.modal-footer {
    border-top: 1px solid var(--golden-second);
}

.monitored-token-item {
    padding: 0;
    padding-top: 10px;
    border-bottom: 1px solid var(--golden-second);
}

.monitored-token-item p {
    margin-bottom: 3px;
}

.monitored-token-item .secondary-button {
    margin: 10px 0;
    padding: 5px;
}

.swap-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px auto;
}

.swap-low,
.swap-low-mid,
.swap-mid,
.swap-hi-mid,
.swap-hi {
    font-size: 0.88rem;
    color: var(--text-light-color);
    border: none;
    border-radius: 10px;
    padding: 8px 15px;
    min-width: calc((1 / 7)* 100%);
    margin: 0 10px;
    transition: background-color .15s ease-in-out;
}

.swap-low {
    background-color: #a36a94;
}

.swap-low-mid {
    background-color: #985886;
}

.swap-mid {
    background-color: #8C4579;
}

.swap-hi-mid {
    background-color: #7e3e6d;
}

.swap-hi {
    background-color: #703761;
}

.custom-swap-container {
    display: flex;
    width: 100%;
    margin-top: 10px;
}

.swap-type-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 5px;
    max-height: 45px;
}

.swap-type-button {
    font-size: 0.88rem;
    font-weight: 600;
    background-color: #531a35;
    color: var(--highlight-second);
    border: 2px solid var(--highlight-prime);
    border-radius: 10px;
    padding: 8px 15px;
    width: 60%;
    margin: 0 auto;
    transition: width .15s ease-in-out;
}

.swap-type-button:hover {
    background-color: var(--highlight-prime);
    color: var(--text-light-color);
    border: 3px solid #531a35;
    width: 90%;
}

.input-swap-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: calc((2 / 3) * 100%);
    margin: 0 auto;
}

.input-swap {
    font-size: 1rem;
    border: none;
    border-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px 10px;
    width: 100%;
}

.swap-submit-button {
    font-size: 1rem;
    font-weight: bold;
    background-color: #8C4579;
    padding: 8px 5px;
    width: 100%;
    color: var(--text-light-color);
    border-radius: 10px;
    border: none;
}

.swap-low:hover,
.swap-low-mid:hover,
.swap-mid:hover,
.swap-hi-mid:hover,
.swap-hi:hover,
.swap-submit-button:hover {
    background-color: #d1b5c9;
    color: var(--text-dark-color);
}

.trade-panel {
    position: fixed;
    right: -500px;
    top: 0;
    height: 100%;
    padding: 1rem 0;
    background-color: var(--secondary-color);
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease;
    z-index: 1050;
}

.trade-panel.open {
    padding: 1rem;
    width: 50%;
    max-width: 500px;
    right: 0;
}

.trade-panel-header {
    /* padding: 10px;
    padding-top: 0; */
    border-bottom: 1px solid var(--text-light-color);
    margin-bottom: 10px;
}

.trade-search-bar {
    width: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 40px;
    font-size: 1.25rem;
    padding: 10px;
    border: none;
    border-right: 1px solid;
}

.trade-dropdown {
    font-size: 1rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px;
    border: none;
    height: 40px;
}

.row-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    margin-bottom: 20px;
}

.col-container {
    display: flex;
    align-items: center;
}

.close-button {
    width: 40px;
    height: 40px;
    color: var(--text-dark-color);
    background-color: var(--text-light-color);
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-radius .15s ease-in-out;
}

.close-button:hover {
    color: var(--text-light-color);
    background-color: var(--text-dark-color);
    border-radius: 25px;
}

.wallet-network {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wallet-button,
.network-button {
    font-size: 0.9rem;
    font-weight: bold;
    height: 30px;
    color: var(--text-dark-color);
    background-color: var(--text-light-color);
    border: none;
    border-radius: 15px;
    margin-left: 10px;
    padding: 0 10px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-radius .15s ease-in-out;
}

/* ----- Trade Panel Inputs (Body) ----- */
.trade-token-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 15px auto;
}

.trade-token-metadata {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.token-address-label {
    display: block;
    margin: 0;
}

.trade-input-display {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.token-search-input,
.token-amount-input {
    background-color: var(--text-light-color);
    padding: 0 10px;
    margin: 0;
    box-sizing: border-box;
    height: 48px;
    line-height: 48px;
    transition: width 0.3s ease, opacity 0.3s ease;
}

.token-search-input {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 3px solid var(--border-prime);
    border-right: none;
    width: 50px;
}

.token-search-input:hover {
    box-shadow: -5px 5px 3px var(--border-second);
}

.token-amount-input {
    border: 3px solid #8C4579;
    border-left: none;
    border-right: none;
    width: 100%;
}

.trade-input-display.expanded .token-search-input {
    width: 100%;
    margin-right: -20px;
}

.trade-input-display.expanded .token-amount-input {
    width: 0;
    opacity: 0;
    overflow: hidden;
}

.trade-input-display.collapsed .token-search-input {
    width: 50px;
}

.trade-input-display.collapsed .token-amount-input {
    width: 100%;
    opacity: 1;
}

.token-address-check {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--text-light-color);
    color: var(--golden-prime);
    /* Temp */
    border: 3px solid #8C4579;
    border-left: none;
    width: 48px;
    height: 48px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.flip-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.flip-button {
    font-size: 1.5rem;
    margin: 0 auto;
    border-radius: 25px;
    background-color: transparent;
    border: none;
    background: linear-gradient(65deg, var(--border-prime) 50%, #a36a94 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.flip-button.rotate {
    animation: rotate 0.5s linear;
    /* Apply the rotation animation */
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(720deg);
    }
}

/* -------------------------- */
.token-address-input {
    font-size: 1rem;
    width: calc((5 / 6)* 100%);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px;
    opacity: 1;
}

.trade-panel-footer {
    padding: 10px;
    border-top: 1px solid var(--text-light-color);
    margin-top: 10px;
}

.transfer-amount-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
    .container {
        max-width: calc((3 / 4) * 100%);
    }

    .token-info-col-left,
    .token-info-col-right {
        width: 100%;
        padding: 5px;
    }

    .token-info {
        flex-direction: column;
    }

    .input-swap-btn-container {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: calc((4 / 5) * 100%);
    }

    .token-post {
        grid-template-rows: auto auto auto auto;
        padding: 8px 15px 5px 0px;
    }

    /* .token-dash-post img,
    .token-post img { */
    .img-button {
        width: 60px;
        border-radius: 25%;
        border: 3px solid var(--border-prime);
        grid-row: 1;
        margin: 0 auto;
    }

    .token-name-symbol {
        font-size: 1rem;
        text-align: left;
    }

    .token-dash-post {
        padding: 10px;
    }

    .token-info {
        text-align: left;
    }

    .token-info>div {
        padding: 5px;
    }

    .token-info-container {
        display: block;
        grid-column: span 2;
        padding-left: 40px;
    }

    .swap-button-container {
        flex-direction: column;
    }

    .swap-low,
    .swap-low-mid,
    .swap-mid,
    .swap-hi-mid,
    .swap-hi {
        width: 100%;
        margin: 5px 0;
    }
}

@media (max-width: 480px) {
    .container {
        max-width: 100%;
    }

    .token-name-symbol {
        font-size: 18px;
    }

    .token-info {
        font-size: 14px;
    }

    .token-price,
    .token-liquidity,
    .token-network {
        font-size: 14px;
    }

    .swap-type-button {
        width: 80%;
    }

    .input-swap-btn-container {
        flex-direction: column;
    }

    .input-swap,
    .swap-submit-button {
        width: 100%;
        margin: 5px 0;
    }
}