.chatbot-container {
    color: var(--white-100);
    height: 70vh;
}

.activate-chatai-container {
    display: flex;
    margin: 1.5rem 0;
}

.chat-window {
    height: 75vh;
    width: 40%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: var(--dark-300);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: fixed;
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;
}

.chat-window.hidden {
    transform: translateX(100%);
    transition: transform 0.2s ease-in-out;
}

.chat-history {
    height: 90%;
    overflow-y: auto;
    margin: 16px;
    margin-bottom: 0;
    font-size: 14px;
    text-align: left;
}

.chat-history::-webkit-scrollbar {
    display: none;
}

.chat-message {
    margin-bottom: 0px;
}

.user-message,
.bot-message {
    background-color: var(--blue-100);
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
    word-wrap: normal;
}

.user-message {
    padding: 16px 8px;
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
}

.bot-message {
    background-color: var(--blue-300);
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
}

.timestamp {
    padding-right: 16px;
    padding-bottom: 8px;
    font-size: 11px;
    text-align: right;
    animation: fadeIn 0.5s ease forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.message-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0.75rem 0.5rem;
    background-color: var(--dark-100);
    border-radius: 15px;
}

.message-input {
    background-color: var(--dark-100);
    color: var(--white-100);
    display: block;
    padding: 0.75rem 0.25rem 0.75rem 1rem;
    border: none;
    border-right: 0;
    border-radius: 15px 0 0 15px;
    outline: none;
    font-size: 1.1rem;
    text-align: left;
    flex: 1 1;
    min-height: 16px;
    overflow-y: auto;
    white-space: pre-wrap;
    resize: none;
    line-height: normal;
    white-space: pre-line;
}

.message-input::-webkit-scrollbar {
    display: none;
}

.button-container {
    /* min-width: 25%; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border: none;
    border-left: 0;
    border-radius: 0 15px 15px 0;
    background-color: var(--dark-100);
    padding-right: 10px;
}

.send-button,
.clear-button {
    height: 100%;
    font-size: 1.25rem;
    border: none;
    background-color: transparent;
    color: var(--white-100);
    cursor: pointer;
    margin: 0 5px;
}

.send-button:hover {
    color: rgba(240, 235, 216, 0.75);
}

.clear-button {
    border-radius: 0 5px 5px 0;
}

.clear-button:hover {
    color: rgba(240, 235, 216, 0.75);
}

@keyframes loading-dots {
    0% {
        content: '.';
    }

    25% {
        content: '..';
    }

    50% {
        content: '...';
    }

    75% {
        content: '....';
    }

    100% {
        content: '.....';
    }
}

.loading-message::after {
    content: '.....';
    animation: loading-dots 1s infinite steps(1);
}

/* MOBILE */

.drawer-container .chatbot-container {
    padding: 0;
    height: 100%;
    max-height: 100vh;
}

.drawer-container .message-input {
    font-size: 16px !important;
}

.drawer-container .activate-chatai-container {
    margin: 0;
    display: none;
}

.drawer-container .chat-window {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
    margin: 0;
    position: static;
    transform: none;
}

.drawer-container .chat-history {
    height: 100%;
}

.drawer-container .message-input-container {
    margin-bottom: 8%;
}

.drawer-container .message-input-container .message-input {
    font-size: 18px;
    padding: 14px;
    padding-right: 5px;
    border-radius: 15px 0 0 15px;
}

.drawer-container .message-input-container .button-container {
    border-radius: 0 15px 15px 0;
}

.drawer-container .message-input-container .send-button {
    font-size: 28px;
    height: 100%;
    max-height: 50px;
    padding: 10px;
}

.drawer-container .message-input-container .clear-button {
    font-size: 28px;
    height: 100%;
    max-height: 50px;
    padding: 10px;
    border-radius: 0 15px 15px 0;
}