.loader-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--dark-100);
    z-index: 999;
}

.pong-loader {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    /* top: 50%; */
    height: 40px;
    width: 6px;
    background-color: transparent;
    animation: paddles 0.75s ease-out infinite;
    transform: translate3d(0, 0, 0);
}

.pong-loader:before {
    content: "";
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 15px;
    width: 10px;
    height: 10px;
    background-color: var(--white-100);
    border-radius: 50%;
    animation: ballbounce 0.6s ease-out infinite;
}

@keyframes paddles {
    0% {
        box-shadow: -25px -10px 0px var(--white-100), 25px 10px 0px var(--white-100);
    }

    50% {
        box-shadow: -25px 8px 0px var(--white-100), 25px -10px 0px var(--white-100);
    }

    100% {
        box-shadow: -25px -10px 0px var(--white-100), 25px 10px 0px var(--white-100);
    }
}

@keyframes ballbounce {
    0% {
        transform: translateX(-20px) scale(1, 1.2);
    }

    25% {
        transform: scale(1.2, 1);
    }

    50% {
        transform: translateX(15px) scale(1, 1.2);
    }

    75% {
        transform: scale(1.2, 1);
    }

    100% {
        transform: translateX(-20px);
    }
}