:root {
  --white-100: #F0EBD8;
  --white-200: #d8d4c2;

  --blue-100: #3e4148;
  /* --blue-200: #31363F; */
  --blue-200: #2b323e;
  --blue-300: #282c34;

  --green-100: #819c7a;
  --green-200: #597266;
  --green-300: #2a493a;

  --royal-blue-100: #2b4053;

  --grey-100: #53565d;

  --red-100: #BC4749;
  --red-200: #a94042;

  --grey-blue: #737387;
  --grey-red: #877373;
  --grey-green: #738773;

  --dark-100: #292929;
  --dark-200: #252525;
  --dark-300: #212121;

  --primary-color: #252526;
  --secondary-color: #3a3839;
  --text-light-color: #D9D5D2;
  --text-dark-color: #26231E;
  --highlight-prime: #1f76c2;
  --highlight-second: #8C8136;
  --alert-prime: #6a3337;
  --alert-second: #9b777a;
  --warning-prime: #595959;
  --warning-second: #8b8b8b;
  --default-prime: #80A8BE;
  --default-second: #a6c2d2;
  --golden-prime: #73695D;
  --golden-second: #595959;
  --border-prime: #175891;
  --border-second: #103b61;
}

body {
  background-color: var(--blue-200) !important;
  color: var(--white-100) !important;
  font-family: Roboto, sans-serif !important;
  height: 100% !important;
}

body::-webkit-scrollbar {
  display: none;
}

.App {
  height: fit-content;
  overflow-y: scroll;
  text-align: center;
}

.App::-webkit-scrollbar {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--blue-200);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Style for NAV Bar */
.tab-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 5vh;
}

.desktop-container {
  position: relative;
}

.full-section {
  position: absolute;
  width: 100%;
  margin: 0 auto;
  z-index: 99;
}

/* Styles for left section */
.left-senti-section,
.left-pred-section,
.left-charts-section {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  float: left;
  padding: 20px;
  padding-top: 0px;
  padding-right: 5px;
  box-sizing: border-box;
  height: 85vh;
}

.left-senti-section {
  width: 60%;
  transition: width 0.2s ease-in-out;
}

.left-senti-section.expanded {
  width: 100%;
  padding-right: 20px;
}

.left-pred-section {
  width: 100%;
  padding: 20px;
}

.left-charts-section {
  width: 50%;
}

/* Styles for right section */
.right-senti-section,
.right-pred-section,
.right-charts-section {
  float: right;
  /* padding: 20px;
  padding-left: 5px; */
  padding: 20px 0;
  box-sizing: border-box;
  height: 85vh;
  display: flex;
  flex-direction: column;
}

.right-senti-section {
  width: 40%;
  transition: width 0.2s ease-in-out;
}

.right-senti-section.collapsed {
  width: 0;
}

.right-pred-section {
  display: none;
}

.right-charts-section {
  width: 50%;
}

.left-section .tv-widget-container {
  display: table;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 80vh;
}

.tv-chart {
  height: 50%;
  display: grid;
}

.tv-screener {
  height: 50%;
  display: grid;
}

.activate-chatai-button {
  color: var(--white-100);
  background-color: var(--dark-100);
  border: none;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 12px;
  font-size: 1rem;
  white-space: nowrap;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 15.5px;
  width: 55px;
  transition: width 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  z-index: 99;
}

.activate-chatai-button::after {
  content: "CAI";
}

.activate-chatai-button:hover::after {
  content: "Catasphere AI";
}

.activate-chatai-button:hover {
  width: 135px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.1);
}

/* Mobile Containers */
.drawer-container {
  height: 100vh;
  overflow-y: scroll;
}

.drawer-container .activate-chatai-button {
  display: none;
}

.mobile-container {
  overflow: hidden;
  height: 100%;
}

.mobile-container .mobile-chart-container .chart-container {
  width: 100vw;
  height: 50vh;
}

.mobile-container .table-container {
  height: 60vh;
}

.mobile-container .control-container {
  padding: 1.5rem;
}