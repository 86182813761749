/* LoginPage.css */

.login-container {
  width: 33%;
  max-width: 500px;
  min-width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid var(--blue-100);
  border-radius: 10px;
  background-color: var(--grey-100);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  /* Center the container vertically */
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-container {
  position: relative;
}

.logo {
  display: flex;
  align-items: center;
  padding: 1.25rem 0;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.logo-error {
  transform: translateY(-25px);
  /* Slide the logo up when there's an error */
}

.error-message {
  text-align: center;
  color: var(--red-200);
  font-size: 18px;
  margin: 0;
  margin-bottom: 5px;
  transition: opacity 1s ease-in-out;
}

.error-message {
  transform: translateY(-25px);
}

.error-message.active {
  opacity: 1;
  /* Show error message */
}

.login-logo {
  width: 5.75rem;
  height: auto;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

:root {
  --input-padding: 10px;
}

input[type="email"],
input[type="password"] {
  /* Subtract twice the padding value */
  width: calc(100% - (var(--input-padding) * 2));
  padding: var(--input-padding);
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid var(--white);
  outline: none;
}

.login-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: var(--white);
  background-color: var(--green-100);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover {
  background-color: var(--green-200);
  /* Hover color */
}

.login-button-active {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: var(--white-200);
  background-color: var(--green-200);
  /* Button color when password length is 8 or more */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.google-signin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: var(--white);
  background-color: var(--dark-100); /* Google Blue */
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.google-signin-button:hover {
  background-color: var(--dark-200); /* Darker Google Blue */
  transform: translateY(-2px);
}

.google-signin-button:active {
  background-color: var(--dark-300); /* Even Darker Google Blue */
  transform: translateY(0);
}

.google-signin-button img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}