.transaction-container {
    max-height: 36vh;
    margin-top: 0.75rem;
    overflow-y: scroll;
    border-radius: 5px;
    text-align: left;
}

.transaction-container::-webkit-scrollbar {
    display: none;
}

.transaction-header {
    display: flex;
    position: sticky;
    top: 0;
    background-color: var(--blue-300);
}

.transaction-row-container {
    border-bottom: 1px solid var(--white-100);
}

.table-cell {
    font-size: 1rem;
    color: var(--white-100);
    flex: 1; /* Equal width for each header cell */
    text-align: center;
}

.transaction-header .table-cell {
    padding: 10px;
    font-size: 0.9rem;
    font-weight: bold;
}

.row-content {
    display: flex;
}

.row-content .table-cell {
    padding: 5px;
    padding-bottom: 0;
    font-weight: normal;
}

.transaction-row-container:nth-child(even) {
    background-color: var(--blue-300);
}

.transaction-row-container:nth-child(odd) {
    background-color: var(--blue-100);
}

/* Mobile Style */  
.drawer-container .transaction-container {
    max-height: 85vh;
    margin: 0;
    border-radius: 0;
}

.drawer-container .transaction-header .table-cell {
    font-size: 1.1rem;
}

.datetime-mobile {
    width: fit-content;
    font-size: 0.75rem;
    color: rgb(240, 235, 216, 0.7);
    padding: 2.5px 10px;
    margin: 10px;
    margin-top: 4px;
    margin-left: auto;
    border: 1px solid rgb(216, 212, 194, 0.7);
    border-radius: 25px;
}
