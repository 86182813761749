/* Navbar container */
.custom-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--white-100); 
    padding: 0.75rem 1.25rem;
    max-height: 10vh;
}

.custom-navbar-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.4rem;
}

.navbar-logo {
    width: 2.25rem;
    height: auto;
    margin-right: 1rem;
}

/* Navbar menu items */
.navbar-menu {
    display: flex;
    align-items: center;
}

/* Navbar menu items on the right */
.navbar-end {
    display: flex;
    align-items: center;
}

/* Navbar menu item */
.navbar-item {
    padding: 0.5rem 1rem; /* Adjust padding */
    color: inherit;
    text-decoration: none;
}

/* Sign out button */
.sign-out-button {
    background-color: var(--blue-200); /* Change button color */
    border: none;
    border-radius: 25px;
    padding: 0.5rem 1rem; /* Adjust padding */
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 17px;
}

.sign-out-button:hover {
    background-color: var(--red-200); /* Change button color on hover */
}
