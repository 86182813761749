.sentimental-container {
    position: relative;
    height: 100%;
}

.sentimental-table-container {
    max-height: 75vh;
    overflow-y: scroll;
    /* Use auto to enable scrolling only when needed */
    border-radius: 5px;
    height: 100%;
    margin-top: 1rem;
}

.sentimental-table-container::-webkit-scrollbar {
    display: none;
}

.sentimental-post {
    text-align: left;
    font-size: 15px;
    background-color: var(--blue-100);
    border-radius: 15px;
    padding: 0.5rem;
    margin-bottom: 0.75rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.fade-in {
    animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.sentinel {
    height: 1px;
}

.load-more-button {
    display: block;
    background-color: transparent;
    color: transparent;
    border: none;
    /* margin: 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    opacity: 0;
    animation: fadeIn 1s forwards; */
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.mobile-container .sentimental-post {
    margin: 0 0.5rem;
    margin-bottom: 0.75rem;
}

.sentimental-post:hover {
    background-color: var(--blue-300);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.sentimental-post:active {
    transform: translateY(0);
}

.post-container {
    padding: 0;
    margin: 0;
}

.post-content {
    margin: 0.5rem 1.25rem;
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Limit the content to 2 lines */
    -webkit-box-orient: vertical;
}

.post-meta {
    color: var(--grey-300);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.post-meta p {
    margin: 0;
}

.post-meta .username {
    font-weight: 700;
}

.post-meta .datetime {
    font-size: 12px;
}

.post-sentiment {
    display: flex;
    justify-content: space-between;
    width: fit-content;
    gap: 15px;
    margin: 0 1.25rem;
    text-transform: uppercase;
    font-weight: 700;
}

.post-sentiment p {
    margin: 0;
}

.post-token-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.post-token {
    display: inline-block;
    padding: 5px 8px;
    margin: 2.5px 5px;
    border: none;
    border-radius: 5px;
    background-color: var(--dark-100);
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none;
    color: inherit;
}

.post-token:hover {
    background-color: var(--dark-300);
}

.popup-container {
    transform: translate(10%, 3.5rem);
}

.popup {
    display: none; 
    transform: translate(12.5%, 5rem);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
}

/* Add a class to the pop-up */
.popup.-active {
    display: block;
    position: absolute;
    z-index: 999;
    background-color: rgb(40, 44, 52, 0.96);
    box-shadow: -10px 20px 25px rgba(15, 25, 38, 0.85);
    padding: 1rem;
    border-radius: 10px;
    white-space: normal;
    overflow-wrap: break-word;
    width: 80%;
    max-height: 50vh;
    overflow-y: scroll;
    font-size: 16px;
    text-align: left;
    transition: all 0.3s ease 0s;
}

.popup.-active::-webkit-scrollbar {
    display: none;
}

/* .popup.-active {
    transform: translate(-50%, -70px);
} */

.fade-up {
    animation: fadeIn 0.1s ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(3.5rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.senti-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* color: var(--white-100); */
    color: var(--blue-300);
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    border: none;
    border-radius: 25px;
    width: 90%;
    margin: 1rem auto;
    margin-bottom: 0;
    box-shadow: 0px 6px 12px rgba(117, 120, 125, 0.15);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    max-width: 500px;
}

/* .senti-container:hover {
    box-shadow: 0px 10px 15px rgba(83, 86, 93, 0.6);
    color: var(--blue-300);
    transform: translateY(-7px);
} */

.senti {
    padding: 0.75em;
    margin: 0;
}

.dropdown-lookback {
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
    width: 90%;
    max-width: 350px;
    text-align: left;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    /* animation: fadeInFromTop 0.3s ease-in-out; */
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: opacity 0.2s ease-in-out, height 0.3s ease-in, visibility 0.15s;
}

.dropdown-lookback-container:hover .dropdown-lookback {
    opacity: 1;
    visibility: visible;
    height: fit-content;
}

.dropdown-lookback-item {
    background-color: var(--blue-100);
    color: var(--white-100);
    /* border-bottom: 1 solid var(--white-100); */
    padding: 8px 20px;
    cursor: pointer;
    color: white;
    border-bottom: 1px solid #444;
}

.dropdown-lookback-item:last-child {
    border-bottom: none;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.custom {
    display: flex;
    align-items: center;
}

.custom input {
    margin-left: 10px;
    padding: 5px;
    width: 60%;
    border-radius: 5px;
    border: none;
    font-size: 14px;
}

.dropdown-lookback-item:hover {
    background-color: var(--blue-300);
}

.rl-data-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.rl-link-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.rl-button-container {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    margin-top: 2rem;
}

.rl-button {
    color: var(--royal-blue-100);
    outline: none;
    cursor: pointer;
    font-size: 0.8rem;
    padding: 0.33rem 0.66rem;
    transition: all 0.3s ease 0s;
}

.origin-link {
    display: flex;
    height: 1.85rem;
    margin-left: 1.25rem;
}

.link-logo {
    width: 100%;
}

.bull {
    background-color: #91b089;
    border: 1 solid var(--white-100);
    border-radius: 5px 0 0 5px;
}

.bull:active {
    transform: translate(-8px, -10px);
}

.neut {
    background-color: #ddd262;
    border: 1 solid var(--white-100);
}

.neut:active {
    transform: translateY(-10px);
}

.bear {
    background-color: #BC4749;
    border: 1 solid var(--white-100);
    border-radius: 0 5px 5px 0;
}

.bear:active {
    transform: translate(8px, -10px);
}